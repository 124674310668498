import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Tooltip } from "@mui/material"
import { useHistory } from "react-router-dom"
import { changeAppSocialPlatform } from "../../../redux/ducks/appSettingsDucks"
import {
  SOCIAL_PLATFORMS_NAMES,
  USER_INDEXES,
} from "../../../constants/appSettings"
import { PlaformSwitchButton } from "../buttons/styledButtons"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import {
  searchResultsPresentSelector,
  userGrantSelector,
} from "../../../redux/selectors"
import useTranslation from "../../../localization/useTranslation"
import { SocialTabsWrapper, TabDisabled, WidthMaxWrapper } from "./tabsStyles"

const SocialPlatformTabs = ({ userindex }) => {
  const { labelStrings } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const platformIndex = userindex || USER_INDEXES.instagram

  const { enable_tt_search, enable_ig_search, enable_yt_search } = useSelector(
    userGrantSelector,
    shallowEqual,
  )
  const searchUsersLength = useSelector(searchResultsPresentSelector)

  const searchGrants = {
    [SOCIAL_PLATFORMS_NAMES.instagram.name]: enable_ig_search,
    [SOCIAL_PLATFORMS_NAMES.tiktok.name]: enable_tt_search,
    [SOCIAL_PLATFORMS_NAMES.youtube.name]: enable_yt_search,
  }

  const toggleSocialPlatform = (newPlatformName) => () => {
    dispatch(changeAppSocialPlatform(newPlatformName, history))
  }

  useEffect(() => {
    const availableSearchGrants = Object.keys(searchGrants)?.find(
      (i) => searchGrants[i] === true,
    )

    if (availableSearchGrants && !searchUsersLength) {
      dispatch(changeAppSocialPlatform(availableSearchGrants, history))
    }
  }, [])

  const TabsData = [
    {
      onClick: toggleSocialPlatform(SOCIAL_PLATFORMS_NAMES.instagram.name),
      userindex: platformIndex,
      isSelected: platformIndex === USER_INDEXES.instagram,
      ownIndex: USER_INDEXES.instagram,
      isDisabled: !enable_ig_search,
      name: SOCIAL_PLATFORMS_NAMES.instagram.name,
      tipText: labelStrings.socialPlatformDisabledTip,
    },
    {
      onClick: toggleSocialPlatform(SOCIAL_PLATFORMS_NAMES.tiktok.name),
      userindex: platformIndex,
      isSelected: platformIndex === USER_INDEXES.tiktok,
      ownIndex: USER_INDEXES.tiktok,
      isDisabled: !enable_tt_search,
      name: SOCIAL_PLATFORMS_NAMES.tiktok.name,
      tipText: labelStrings.socialPlatformDisabledTip,
    },
    {
      onClick: toggleSocialPlatform(SOCIAL_PLATFORMS_NAMES.youtube.name),
      userindex: platformIndex,
      isSelected: platformIndex === USER_INDEXES.youtube,
      ownIndex: USER_INDEXES.youtube,
      isDisabled: !enable_yt_search,
      name: SOCIAL_PLATFORMS_NAMES.youtube.name,
      tipText: labelStrings.socialPlatformDisabledTip,
    },
  ]

  return (
    <SocialTabsWrapper isDisabled={!userindex}>
      {TabsData?.map((el) => {
        return el.isDisabled ? (
          <WidthMaxWrapper key={el.ownIndex}>
            <Tooltip
              userindex={userindex}
              title={el.tipText}
              arrow
              placement='bottom-start'
              componentsProps={{
                tooltip: {
                  sx: {
                    maxWidth: '250px',
                    fontSize: "13px",
                    fontFamily: "Inter",
                    p: 1,
                    px: 2,
                    backgroundColor: "#F6DBDB",
                    color: 'black',
                      '& .MuiTooltip-arrow': {
                        color: '#F6DBDB'
                      },
                  },
                },
              }}
            >
              <TabDisabled>
                <PlaformSwitchButton
                  onClick={el.onClick}
                  userindex={el.userindex}
                  isSelected={el.isSelected}
                  ownIndex={el.ownIndex}
                  isDisabled={el.isDisabled}
                >
                  {el.name}
                </PlaformSwitchButton>
              </TabDisabled>
            </Tooltip>
          </WidthMaxWrapper>
        ) : (
          <WidthMaxWrapper key={el.ownIndex}>
            <PlaformSwitchButton
              onClick={el.onClick}
              userindex={el.userindex}
              isSelected={el.isSelected}
              ownIndex={el.ownIndex}
              isDisabled={el.isDisabled}
            >
              {el.name}
            </PlaformSwitchButton>
          </WidthMaxWrapper>
        )
      })}
    </SocialTabsWrapper>
  )
}

SocialPlatformTabs.propTypes = {
  userindex: PropTypes.string,
}

export default SocialPlatformTabs
