// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import { TopControls } from '../../sections/creator-overview/TopControls'
import { UsersList } from '../../sections/creator-overview/UsersList'
import { EditCreator } from '../../sections/creator-overview/EditCreator'
import { useDispatch, useSelector } from 'react-redux'
import {
  creatorListSelector,
  currentOpenedCampaignSelector,
  allCampaignsSelector,
} from '../../../redux/selectors'
import {
  changeContentListData,
  changeCreator,
  changeDataCreatorList,
  changeDataDashboard,
  editDashboard,
  getCampaignCreatorList,
} from '../../../redux/ducks/campaignsDucks'
import SuggestedCreatorsInputContainer from '../../components/suggested-сreators'
import { filteredData, getCreatorOverviewFieldsData, updateObjectById } from '../../sections/creator-overview/utils'

export const CreatorOverview = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const ref = useRef(null)

  const currentCampaign = useSelector(currentOpenedCampaignSelector)
  const { campaignId } = currentCampaign
  const { data: listData, isLoading } = useSelector(creatorListSelector)
  const { trackNow, trackMax } = useSelector(allCampaignsSelector)
  const isAllowedToAddCreators = trackNow < trackMax

  const [openEditContentModal, setOpenEditContentModal] = useState(false)
  const [editItem, setEditItem] = useState({})
  const [currPage, setCurrPage] = useState(0)

  const filterCurrentOverview = editItem?.id && filteredData(listData, editItem?.id)
  const creatorOverviewFieldsData =
    filterCurrentOverview && getCreatorOverviewFieldsData(filterCurrentOverview)

  const creatorsListData = listData?.map(creator => {
    let status = 'active'
    if (creator.fromCampaignData?.isArchived) {
      status = 'archived'
    } else if (creator.fromCampaignData?.isOnboarding) {
      status = 'onboarding'
    }
    return {
      id: creator._id,
      name: creator.username,
      platform: creator.fromCampaignData?.platform,
      reachInViews: creator.overallReach,
      likes: creator.likesOverall,
      matchMethod: creator.fromCampaignData?.matchMethod || 'phrase',
      lastActivity: creator.lastActivity,
      added: creator.fromCampaignData?.dateAdded,
      status: status,
      avatarUrl: creator.profilePicUrl,
      isBrandAccount: creator.fromCampaignData?.isBrandAccount,
      fromCampaignData: creator.fromCampaignData,
    }
  })

  useEffect(() => {
    if (campaignId && !listData?.length) {
      dispatch(getCampaignCreatorList({ campaignId }))
    }
  }, [campaignId, listData?.length])

  const onHandleChangeCreator = ({ creatorId, isArchived }) => {
    dispatch(
      changeCreator({
        campaignId,
        creatorId,
        isArchived: !isArchived,
      })
    )
  }

  const onHandleDeleteCreator = ({ creatorId }) => {
    dispatch(
      changeCreator({
        campaignId,
        creatorId,
        isDeleted: true,
      })
    )
  }

  const onHandleChangeMatchedContent = ({ creatorId, matchedValue }) =>
    dispatch(
      changeCreator({
        campaignId,
        creatorId,
        matchMethod: matchedValue,
      })
    )

  const onHandleAddRemoveBrandAccount = ({ isBrandAccount, creatorId }) => {
    dispatch(
      changeCreator({
        campaignId,
        creatorId,
        isBrandAccount: !isBrandAccount,
      })
    )
  }

  const onChangeContentOverviewFilters = id => {
    dispatch(changeContentListData([]))
    history.push(`/campaigns/${campaignId}/content-overview?selectedCreators=${id}`)
  }

  const onEditCreator = (fieldsToChange, creatorId, row, withoutRefetch) => {
    const getCreatorId = editItem?.id || creatorId

    const updatedDataArray = updateObjectById(listData, creatorId, {
      fromCampaignData: {
        ...row?.fromCampaignData,
        pricing: fieldsToChange?.pricing,
      },
    })

    dispatch(
      changeCreator({
        withoutRefetchCreatorListData: withoutRefetch,
        campaignId,
        creatorId: getCreatorId,
        ...fieldsToChange,
      })
    )
    dispatch(changeDataCreatorList(updatedDataArray))
  }

  const onAddNonRecognizedContent = ({ creatorId }) => {
    history.replace(
      `/campaigns/${campaignId}/content-overview?selectedCreators=${creatorId}&openUnmatchedContent=true`
    )
  }

  const onViewCreatorAnalytics = ({ dashboardId, creatorId }) => {
    dispatch([
      changeDataDashboard([]),
      editDashboard({
        selectedCreators: [creatorId],
        startingDate: '',
        endingDate: '',
        dashboardId,
        campaignId,
        withRefetch: true,
        method: 'change',
        history,
      }),
    ])
  }

  const onActivateCreator = creatorId => {
    dispatch(
      changeCreator({
        campaignId,
        creatorId,
        isOnboarding: false,
      })
    )
  }

  useEffect(() => {
    ref?.current?.firstElementChild?.scrollIntoView()
  }, [currPage, ref])

  return (
    <>
      <Box ref={ref} sx={{ px: 3, py: 3 }}>
        <TopControls />
        <Box>
          <SuggestedCreatorsInputContainer
            campaignId={campaignId}
            creatorsTableList={listData}
            isAllowedToAddCreators={isAllowedToAddCreators}
          />
        </Box>
        <UsersList
          data={creatorsListData}
          openModal={setOpenEditContentModal}
          setEditItem={setEditItem}
          onHandleChangeCreator={onHandleChangeCreator}
          onHandleAddRemoveBrandAccount={onHandleAddRemoveBrandAccount}
          isLoading={isLoading}
          onChangeContentOverviewFilters={onChangeContentOverviewFilters}
          onHandleChangeMatchedContent={onHandleChangeMatchedContent}
          onAddNonRecognizedContent={onAddNonRecognizedContent}
          onViewCreatorAnalytics={onViewCreatorAnalytics}
          onActivateCreator={onActivateCreator}
          onEditCreator={onEditCreator}
          setCurrPage={setCurrPage}
          onHandleDeleteCreator={onHandleDeleteCreator}
        />
      </Box>
      <EditCreator
        openEditContentModal={openEditContentModal}
        setOpenEditContentModal={setOpenEditContentModal}
        editItem={editItem}
        setEditItem={setEditItem}
        editFields={creatorOverviewFieldsData}
        onEditCreator={onEditCreator}
      />
    </>
  )
}
