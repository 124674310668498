import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { useSelector } from 'react-redux'
import { usernameSelector } from '../../../redux/selectors'

import { withReduxActions } from '../../../hocs/withRedux'
import { toggleContactUs } from '../../../redux/ducks/appSettingsDucks'
import { changeUserPassword } from '../../../redux/ducks/userDucks'

import AccountSettings from './AccountSettings'
import ChangePasswordDialog from '../../common/dialogs/ChangePasswordDialog'
import { WithPopupWrapper } from '../../common/styledWrappers'

const AccountSettingsContainer = ({ toggleContactUs, changeUserPassword, isCanceledStatus, isAddPaymentMethod, setIsOpenEditDetails }) => {
  const email = useSelector(usernameSelector)

  const [isModalOpened, setIsModalOpened] = useState(false)
  const toggleIsModalOpened = () => setIsModalOpened(!isModalOpened)

  const [isLoading, setIsLoading] = useState(false)

  const changePassword = async () => {
    setIsLoading(true)
    await changeUserPassword(email)
    setIsLoading(false)
    toggleIsModalOpened()
  }

  return (
    email && (
      <>
        <AccountSettings
          changePassword={toggleIsModalOpened}
          email={email}
          openContactUs={toggleContactUs}
          isCanceledStatus={isCanceledStatus}
          isAddPaymentMethod={isAddPaymentMethod}
          setIsOpenEditDetails={setIsOpenEditDetails}
        />
        {isModalOpened && (
          <WithPopupWrapper isTransparent fullScreen withBlurBG>
            <Popup
              open={isModalOpened}
              position="right center"
              closeOnDocumentClick={false}
              closeOnEscape={false}
              modal
            >
              {close => (
                <ChangePasswordDialog
                  close={toggleIsModalOpened}
                  confirm={changePassword}
                  isLoading={isLoading}
                />
              )}
            </Popup>
          </WithPopupWrapper>
        )}
      </>
    )
  )
}

AccountSettingsContainer.propTypes = {
  toggleContactUs: PropTypes.func,
  changeUserPassword: PropTypes.func,
  isCanceledStatus: PropTypes.bool,
  isAddPaymentMethod: PropTypes.bool,
  setIsOpenEditDetails: PropTypes.func
}

export default withReduxActions({ toggleContactUs, changeUserPassword })(AccountSettingsContainer)
