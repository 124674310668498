import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import useTranslation from "../../../localization/useTranslation"
import CommonSettingSection from "../CommonSettingSection"
import PrimaryButton from "../../common/buttons/PrimaryButton"
import WithTip from "../../common/popups/WithTip"
import { InfoIcon } from "../../common/icons"
import {
  ContactUs,
  TipHoveredText,
  TipIconWrapper,
} from "../../common/styledWrappers"
import { Typography, Stack, Button } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"
import { changePaymentMethod } from "../../../redux/ducks/userDucks"
import { userDetailsSelector } from "../../../redux/selectors"
import { setError } from "../../../redux/ducks/errorDucks"

const _REQUIRED_ADDRESS_FIELDS = ['first_name', 'last_name', 'company', 'line1', 'zip', 'city', 'country']
const isAnyKeyMissingOrEmpty = (object, keys) => {
  if (!object) {
    return true
  }
  return keys.some(key => !(key in object) || object[key] === null || object[key] === undefined || object[key] === '')
}

const ContentWrapper = styled.div``

const Username = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.color.mineShaft};
`

const LabelWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`

const Label = styled.span`
  margin-right: 5px;
  text-transform: uppercase;
  color: ${(props) => props.theme.color.grey};
  font-size: 13px;
`

const AccountSettings = ({
  changePassword,
  email = "",
  openContactUs,
  isAddPaymentMethod,
  isCanceledStatus,
  setIsOpenEditDetails
}) => {
  const { labelStrings } = useTranslation()
  const { billing_address } = useSelector(userDetailsSelector) || {}
  const dispatch = useDispatch()

  const isOpenEditAddress = isAnyKeyMissingOrEmpty(billing_address, _REQUIRED_ADDRESS_FIELDS)

  const onAddPaymentMethod = () => {
    if(isOpenEditAddress) {
      dispatch(setError(labelStrings.pleaseAddBillingErrorMsg))
      setIsOpenEditDetails(true)
    } else {
      dispatch(changePaymentMethod())
    }
  }

  const CustomButton = (
    <PrimaryButton
      label={labelStrings.changePassword}
      onClick={changePassword}
    />
  )

  const TipComponent = (
    <TipHoveredText>
      {labelStrings.please}{" "}
      <ContactUs onClick={openContactUs}>
        {labelStrings.contactUs.toLowerCase()}
      </ContactUs>{" "}
      {labelStrings.ifYouLikeToChangeEmail}
    </TipHoveredText>
  )

  const Content = (
    <ContentWrapper>
      <LabelWrapper>
        <Label>{labelStrings.email}</Label>
        <WithTip position='right center' TipComponent={TipComponent} useWhiteBG>
          <TipIconWrapper>
            <InfoIcon />
          </TipIconWrapper>
        </WithTip>
      </LabelWrapper>

      <Username>{email}</Username>

      {(isAddPaymentMethod || isCanceledStatus) && (
        <Stack sx={{ maxWidth: "350px", mt: 1 }}>
          <Label>{labelStrings.subscriptionStatus}</Label>
          <Typography
            sx={{ fontFamily: "Inter", fontSize: "15px", fontWeight: 700, color: isCanceledStatus ? '#FF5D5A' : '#393939' }}
          >{isCanceledStatus ? labelStrings.cancelled :labelStrings.inTrial}</Typography>

          {!isCanceledStatus && <Typography
            sx={{ fontFamily: "Inter", fontSize: "11px", color: "#FF5D5A", whiteSpace: "pre-wrap", }}
          >
            {labelStrings.statusDescription}
          </Typography> }

          <Stack sx={{ mt: 1 }}>
            <Typography
              sx={{ fontFamily: "Inter", fontSize: "15px", fontWeight: 700, color: '#393939' }}
            >
              {labelStrings.addPaymentMethod}
            </Typography>
            <Button
              sx={{
                mt: 0.5,
                fontFamily: "Inter",
                fontSize: "13px",
                borderRadius: "10px",
                textTransform: "uppercase",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#FF726F",
                },
              }}
              onClick={() => onAddPaymentMethod()}
              variant='outlined'
            >
              {labelStrings.addPaymentMethod}
            </Button>
            <Typography
              sx={{
                ml: 2,
                mt: 0.5,
                fontFamily: "Inter",
                fontSize: "11px",
                color: "#FF5D5A",
              }}
            >
              *{labelStrings.pleaseAddBilling}
            </Typography>
          </Stack>
        </Stack>
      )}
    </ContentWrapper>
  )

  return (
    <CommonSettingSection
      title={labelStrings.yourAccount}
      Button={CustomButton}
      Content={Content}
    />
  )
}

AccountSettings.propTypes = {
  changePassword: PropTypes.func,
  email: PropTypes.string,
  openContactUs: PropTypes.func,
  setIsOpenEditDetails: PropTypes.func
}

export default AccountSettings
