import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ContactUs, TipHoveredText } from '../../common/styledWrappers'
import {
  validateEmail,
  validateOnlyDigits,
  getCountryNameByCode,
  getCountryCodeByName,
  getIsEuropeanCountrySelected,
} from '../../../utils'
import useTranslation from '../../../localization/useTranslation'

import BillingDetailsForm from './BillingDetailsForm'

const BillingDetailsFormContainer = ({
  currentBillingDetails,
  updateBillingDetails,
  openContactUs,
  isBillingDetailsPresent = false,
  isOpenEditDetails = false
}) => {
  const { labelStrings } = useTranslation()

  const [isEditDetails, setIsEditDetails] = useState(false)
  const toggleIsEditDetails = () => setIsEditDetails(!isEditDetails)

  const [detailsState, setDetailsState] = useState(currentBillingDetails)

  const createDefaultValidations = () => ({
    firstName: !!currentBillingDetails.firstName,
    lastName: !!currentBillingDetails.lastName,
    company: !!currentBillingDetails.company,
    addressLine1: !!currentBillingDetails.addressLine1,
    zip: !!currentBillingDetails.zip && validateOnlyDigits(currentBillingDetails.zip),
    city: !!currentBillingDetails.city,
    vatId: getIsEuropeanCountrySelected(currentBillingDetails.country)
      ? !!currentBillingDetails.vatId
      : true,
    billingEmail: !!currentBillingDetails.billingEmail
      ? validateEmail(currentBillingDetails.billingEmail)
      : true,
  })
  const [validations, setValidations] = useState(createDefaultValidations())

  useEffect(() => {
    setDetailsState(currentBillingDetails)
    setValidations(createDefaultValidations())
  }, [currentBillingDetails])

  useEffect(() => {
    validateVatId()
  }, [detailsState.country])

  useEffect(() => {
    if(isOpenEditDetails) {
      toggleIsEditDetails()
    }
  }, [isOpenEditDetails])

  const isAllValid = Object.values(validations).every(el => !!el)

  const validateNotEmptyField = fieldName => () => {
    if (!detailsState[fieldName]) setValidations({ ...validations, [fieldName]: false })
  }

  const resetValidation = fieldName => () => {
    if (!validations[fieldName]) setValidations({ ...validations, [fieldName]: true })
  }

  const validateDetailsEmail = () => {
    if (!detailsState.billingEmail) return true // validate email only if it's not empty
    const isValid = validateEmail(detailsState.billingEmail)
    if (!isValid) setValidations({ ...validations, billingEmail: false })
  }

  const validateZip = () => {
    const isValid = !!detailsState.zip && validateOnlyDigits(detailsState.zip)
    if (!isValid) setValidations({ ...validations, zip: false })
  }

  const validateVatId = () => {
    let isValid = true
    if (getIsEuropeanCountrySelected(detailsState.country)) {
      isValid = !!detailsState.vatId
    }
    setValidations({ ...validations, vatId: isValid })
  }

  const onSubmit = e => e.preventDefault()

  const onInputChange = detailName => ({ target, value }) => {
    if (detailName === 'country') {
      return setDetailsState({ ...detailsState, [detailName]: getCountryCodeByName(value) })
    }
    setDetailsState({ ...detailsState, [detailName]: target.value })
  }

  const onButtonClick = () => {
    if (isEditDetails) {
      if (isAllValid) {
        updateBillingDetails(detailsState)
      }
    }
    toggleIsEditDetails()
  }

  const CountryDisableTip = (
    <TipHoveredText>
      {labelStrings.please}{' '}
      <ContactUs onClick={openContactUs}>{labelStrings.contactUs.toLowerCase()}</ContactUs>{' '}
      {labelStrings.to} {labelStrings.editCountry}
    </TipHoveredText>
  )

  const BillingEmailTip = <TipHoveredText>{labelStrings.billingEmailTip}</TipHoveredText>

  const rowOneList = [
    {
      label: labelStrings.firstName,
      value: detailsState.firstName,
      onChange: onInputChange('firstName'),
      isRequired: isEditDetails,
      isValid: validations.firstName,
      onBlur: validateNotEmptyField('firstName'),
      onFocus: resetValidation('firstName'),
    },
    {
      label: labelStrings.lastName,
      value: detailsState.lastName,
      onChange: onInputChange('lastName'),
      isRequired: isEditDetails,
      isValid: validations.lastName,
      onBlur: validateNotEmptyField('lastName'),
      onFocus: resetValidation('lastName'),
    },
    {
      label: labelStrings.company,
      value: detailsState.company,
      onChange: onInputChange('company'),
      isRequired: isEditDetails,
      isValid: validations.company,
      onBlur: validateNotEmptyField('company'),
      onFocus: resetValidation('company'),
    },
    {
      label: labelStrings.addressLine1,
      value: detailsState.addressLine1,
      onChange: onInputChange('addressLine1'),
      isRequired: isEditDetails,
      isValid: validations.addressLine1,
      onBlur: validateNotEmptyField('addressLine1'),
      onFocus: resetValidation('addressLine1'),
    },
    {
      label: labelStrings.addressLine2,
      value: detailsState.addressLine2,
      onChange: onInputChange('addressLine2'),
      isValid: true,
    },
  ]

  const rowTwoList = [
    {
      label: labelStrings.zip,
      value: detailsState.zip,
      onChange: onInputChange('zip'),
      isRequired: isEditDetails,
      isValid: validations.zip,
      onBlur: validateZip,
      onFocus: resetValidation('zip'),
    },
    {
      label: labelStrings.city,
      value: detailsState.city,
      onChange: onInputChange('city'),
      isRequired: isEditDetails,
      isValid: validations.city,
      onBlur: validateNotEmptyField('city'),
      onFocus: resetValidation('city'),
    },
    {
      label: labelStrings.country,
      value: getCountryNameByCode(detailsState.country),
      onChange: onInputChange('country'),
      isDisabled: !!getCountryNameByCode(currentBillingDetails.country) || !isEditDetails,
      Tip: !!getCountryNameByCode(currentBillingDetails.country) && CountryDisableTip,
      isRequired: isEditDetails,
      isValid: true,
    },
    {
      label: labelStrings.vatId,
      value: detailsState.vatId,
      onChange: onInputChange('vatId'),
      isRequired: isEditDetails && getIsEuropeanCountrySelected(detailsState.country),
      isValid: validations.vatId,
      onBlur: validateVatId,
      onFocus: resetValidation('vatId'),
      defaultPrefix: detailsState.country,
    },
    {
      label: labelStrings.billingEmail,
      value: detailsState.billingEmail,
      onChange: onInputChange('billingEmail'),
      isValid: validations.billingEmail,
      onBlur: validateDetailsEmail,
      onFocus: resetValidation('billingEmail'),
      Tip: BillingEmailTip,
    },
  ]

  return (
    <BillingDetailsForm
      onSubmit={onSubmit}
      rowOneList={rowOneList}
      rowTwoList={rowTwoList}
      isEditDetails={isEditDetails}
      onButtonClick={onButtonClick}
      isAllValid={isAllValid}
      isDisabledEdit={!isBillingDetailsPresent}
    />
  )
}

BillingDetailsFormContainer.propTypes = {
  currentBillingDetails: PropTypes.object,
  changeBillingDetails: PropTypes.func,
  openContactUs: PropTypes.func,
  isBillingDetailsPresent: PropTypes.bool,
  isOpenEditDetails: PropTypes.bool
}

export default BillingDetailsFormContainer
