import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  isUserDetailsPresentSelector,
  userDetailsSelector,
  isUserDetailsErrorSelector,
} from '../../../redux/selectors'
import { withReduxActions } from '../../../hocs/withRedux'
import { changePaymentMethod } from '../../../redux/ducks/userDucks'

import Payments from './Payments'

const PaymentsContainer = ({ changePaymentMethod }) => {
  const isUserDetailsPresent = useSelector(isUserDetailsPresentSelector)
  const userDetails = useSelector(userDetailsSelector)
  const isUserDetailsError = useSelector(isUserDetailsErrorSelector)

  const { invoices = [], profile_data = {} } = userDetails

  return (
    <Payments
      isUserDetailsPresent={isUserDetailsPresent}
      invoices={invoices}
      changePaymentMethod={changePaymentMethod}
      profileData={profile_data}
      isUserDetailsError={isUserDetailsError}
    />
  )
}

PaymentsContainer.propTypes = {
  changePaymentMethod: PropTypes.func,
}

export default withReduxActions({ changePaymentMethod })(PaymentsContainer)
