import React from 'react'
import PropTypes from 'prop-types'
import useTranslation from '../../../localization/useTranslation'

import CommonSettingSection from '../CommonSettingSection'
import BillingDetailsFormContainer from '../../forms/billingDetailsForm/BillingDetailsFormContainer'

const BillingDetails = ({
  updateBillingDetails,
  openContactUs,
  isUserDetailsPresent = false,
  currentBillingDetails = {},
  isUpdateLoading = false,
  isUserDetailsError = false,
  isBillingDetailsPresent = false,
  isOpenEditDetails = false
}) => {
  const { labelStrings } = useTranslation()

  const Content = (
    <BillingDetailsFormContainer
      currentBillingDetails={currentBillingDetails}
      updateBillingDetails={updateBillingDetails}
      openContactUs={openContactUs}
      isBillingDetailsPresent={isBillingDetailsPresent}
      isOpenEditDetails={isOpenEditDetails}
    />
  )

  return (
    <CommonSettingSection
      title={labelStrings.billingDetails}
      Content={Content}
      withLoading
      isLoading={!isUserDetailsPresent || isUpdateLoading}
      isError={isUserDetailsError}
      errorLabel={labelStrings.notAvailableBillingDetails}
    />
  )
}

BillingDetails.propTypes = {
  updateBillingDetails: PropTypes.func,
  openContactUs: PropTypes.func,
  isUserDetailsPresent: PropTypes.bool,
  currentBillingDetails: PropTypes.object,
  isUpdateLoading: PropTypes.bool,
  isUserDetailsError: PropTypes.bool,
  isBillingDetailsPresent: PropTypes.bool,
  isOpenEditDetails: PropTypes.bool
}

export default BillingDetails
